<template>
  <main>
    <header-black />
    <buildings-descr />
    <buildings-list />
    <home-contact-us />
  </main>
</template>

<script>
export default {
  name: 'BuildingsPage',
  components: {
    HeaderBlack: () => import('@/components/HeaderBlack'),
    BuildingsDescr: () => import('@/components/BuildingsDescr'),
    BuildingsList: () => import('@/components/BuildingsList'),
    HomeContactUs: () => import('@/components/HomeContactUs')
  }
}
</script>
